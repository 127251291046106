import { gsap, Power2, Circ } from "gsap"
import inView from 'in-view'
export default class Categories {
    constructor() {
        this.input = document.querySelector("#explorer_category_id")
        this.events()
    }

    issueEvent(_btn) {
        _btn.addEventListener("mouseenter", () => {
            let target = _btn.dataset.target
            gsap.to(target, {
                duration: .45,
                display: "block",
                opacity: 1
            })
        })
        _btn.addEventListener("mouseleave", () => {
            let target = _btn.dataset.target
            gsap.to(target, {
                duration: .45,
                display: "none",
                opacity: 0
            })
        })
    }

    events() {
        document.querySelectorAll(".issue-btn").forEach((el) => this.issueEvent(el))
        document.querySelectorAll(".category-btn").forEach((el) => this.catEvent(el))
        inView(".card").on('enter', (el) => {
            if (!el.classList.contains("inview")) {
                this.showCard(el)
                el.classList.add("inview")
            }
        })
    }

    showCard(card) {
        gsap.set(card, {
            y: 30,
            opacity: 0
        })
        gsap.to(card, {
            duration: .65,
            ease: Circ.easeOut,
            opacity: 1,
            y: 0
        })
    }

    catEvent(_btn) {
        let indicator = document.querySelector("#domain-indicator")
        _btn.addEventListener("click", (e) => {
            e.preventDefault()
            this.input.value = _btn.dataset.id
            indicator.innerText = _btn.dataset.name
            if (this.categoryActive) this.categoryActive.classList.remove("active")
            _btn.classList.add("active")
            this.filterIssues()
            this.showSection("#critical-issues", true)
        })
    }

    scrollTo(_section) {
        let top = document.querySelector(_section).offsetTop
        let scroll = {
            y: window.scrollY
        }
        gsap.to(scroll, {
            y: top,
            onUpdate: () => {
                window.scroll(0, scroll.y)
            }
        })
    }

    get categoryActive() {
        return document.querySelector(".category-btn.active")
    }
    
    get id() {
        return this.input.value
    }

    filterIssues() {
        gsap.set(`.issue:not(.issue-${this.id})`, {
            display: "none",
            opacity: 0
        })
        gsap.set(`.issue-${this.id}`, {
            display: "block",
            opacity: 1
        })
    }

    showSection(section, scroll=false) {
        gsap.to(section, {
            opacity: 1,
            display: "block"
        })
        if (scroll) {
            gsap.delayedCall(.1, () => this.scrollTo(section))
        }
    }

    show() {
        // this.showScroll()
        this.showSection("#categories-section", true)
    }

    showScroll() {
        const scroll = document.querySelector("#country-selector .scroll")
        scroll.classList.remove("disabled")
        gsap.to(scroll, {
            ease: Power2.easeOut,
            duration: .65,
            opacity: .8,
            y: 0
        })
    }
    
    hide() {
        gsap.set("#categories-section", {
            display: "none"
        })
    }
}