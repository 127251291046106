import Credits from './components/credits'
import Explorer from './components/explorer'
import Preloader from './components/preloader'
import Header from './components/header'
import "./core/functions"
import "./core/constants"
import inView from 'in-view'
import {
    gsap,
    Power2
} from 'gsap'
class App {
    constructor() {
        this.preloader = new Preloader()
        this.credits = new Credits("🌎 Pro.Fusions | Beyond Boundaries")
        this.explorer = new Explorer()
        this.header = new Header()
        //
        this.explorers = document.querySelector("#explorers-list")
        this.explorersSlider()
        // window.event.on('resize', () => this.resize())
        this.setInview()
        this.events()
    }

    events() {
        const shareButton = document.querySelector('#share-btn');
        const copyLinkBtn = document.querySelector('#copy-link-btn');
        if (shareButton) {
            if ('share' in navigator) {
                shareButton.addEventListener('click', (e) => {
                    e.preventDefault()
                    navigator.share({
                            title: document.querySelector("title").innerText,
                            text: 'Pro.fusions Beyond Boundaries is a participatory futures engagement to explore potential impacts of the intersectional forces and threats shaping human and planetary security.',
                            url: window.location.href
                        })
                })
                gsap.set('.share-fallback', {
                    display: "none"
                })
            } else {
                gsap.set(shareButton, {
                    display: "none"
                })
            }
        }
        if (copyLinkBtn) {
            copyLinkBtn.addEventListener("click", (e) => {
                e.preventDefault()
                let textArea = document.createElement("textarea")
                textArea.value = copyLinkBtn.dataset.url
                document.body.appendChild(textArea)
                textArea.select()
                document.execCommand('copy')
                document.body.removeChild(textArea)
                copyLinkBtn.querySelector("span").innerText = "Copied link!"
                gsap.delayedCall(1, () => {
                    copyLinkBtn.querySelector("span").innerText = "Copy link"
                })
            });
        }
    }

    setInview() {
        inView(".inview-item").on('enter', (el) => {
            if (!el.classList.contains("inview")) {
                this.showItem(el)
                el.classList.add("inview")
            }
        })
    }

    showItem(el) {
        gsap.to(el, {
            duration: 1.15,
            ease: Power2.easeOut,
            opacity: 1,
            y: 0
        })
    }

    explorersSlider() {
        if (!this.explorers) return false
        let slider = new Swiper('.swiper-container', {
            slidesPerView: this.slideParams.perView,
            slidesPerColumn: this.slideParams.perColumn,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: ".swiper-pagination",
                type: "progressbar",
            },
            on: {
                beforeResize: () => {
                    slider.params.slidesPerView = this.slideParams.perView
                    slider.params.slidesPerColumn = this.slideParams.perColumn
                    slider.init()
                }
            }
        })
    }

    get slideParams() {
        let [perView, perColumn] = [5, 3]
        if (innerWidth < 640) {
            perView = 2
            if (innerHeight > 900) {
                perColumn = 5
            } else if (innerHeight > 720) {
                perColumn = 4
            } else {
                perColumn = 3
            }
        } else if (innerWidth >= 640 && innerWidth < 1200) {
            perView = 3
            perColumn = 3
            perColumn = 2
            if (innerHeight > 1024) {
                perColumn = 4
            } else if (innerHeight > 960) {
                perColumn = 3
            }
        }
        return {
            perView: perView,
            perColumn: perColumn
        }
    }

    loaded() {
        window.scroll(0, 0)
        this.preloader.hide()
    }

}

const _app = new App()
window.onload = _app.loaded()
window.addEventListener("pageshow",  (event) => {
    let historyTraversal = event.persisted || (typeof window.performance != "undefined" && window.performance.navigation.type === 2)
    if (historyTraversal) window.location.reload()
    else _app.loaded()
})