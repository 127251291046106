import { gsap, Circ } from "gsap"
export default class Preloader {
    constructor() {
        this.container = document.querySelector("#preloader")
        this.href = null
        this.setTL()
        this.events()
    }

    setTL() {
        this.tl = new gsap.timeline({
            pause: true,
            onReverseComplete: () => {
                if (this.href) window.location = this.href
            }
        }).to(this.container.querySelector(".icon"), {
            duration: .35,
            ease: "power2.easeIn",
            opacity: 0,
            scaleX: 2,
            scaleY: 2
        }, .35).to(this.container, {
            ease: "power2.easeOut",
            duration: .35,
            opacity: 0,
            display: "none"
        }, .45).to("#main-nav", {
            duration: 0.65,
            ease: Circ.easeOut,
            opacity: 1,
            y: 0
        }, .55).to("#main-title .masked-text span", {
            duration: 0.65,
            ease: Circ.easeOut,
            clipPath: "inset(0% 0% 0% 0%)",
            stagger: 0.1,
            force3D: true,
            opacity: 1,
            y: 0
        }, .65).to("#main-title .sub-text", {
            ease: Circ.easeOut,
            duration: 0.65,
            force3D: true,
            opacity: 1,
            stagger: 0.1,
            y: 0
        }, .85)
    }

    events() {
        this.links = document.querySelectorAll(".change-page")
        this.links.forEach(el => this.addLinkEvent(el))
        //
        this.keypress = false
        window.addEventListener("keypress", () => {
            this.keypress = true
        })
        window.addEventListener("keyup", () => {
            this.keypress = false
        })
        let explorerform = document.querySelector("#explorer-form")
        if (explorerform) {
            explorerform.addEventListener("submit", () => {
                this.scrollTop()
                this.show()
            })
        }
    }

    addLinkEvent(link) {
        link.addEventListener("click", (e) => {
            if (!this.keypress) {
                e.preventDefault()
                this.href = link.getAttribute("href")
                this.theme = link.dataset.theme
                this.show()
                this.scrollTop()
            }
        })
    }

    scrollTop() {
        let scroll = {
            y: window.scrollY
        }
        gsap.to(scroll, {
            y: 0,
            onUpdate: () => {
                window.scroll(0, scroll.y)
            }
        })
    }

    hide() {
        this.scrollTop()
        this.tl.duration(2).play()
    }
    
    show() {
        this.container.removeAttribute("class")
        this.container.setAttribute("class", `${this.theme}-theme`)
        this.tl.duration(1).reverse()
    }
}