import { gsap, Power2 } from "gsap"
import Categories from "./categories"
// import Choices from "choices.js"
export default class Explorer {
    constructor() {
        this.cat_input = document.querySelector("#explorer_category_id")
        this.issue_a_input = document.querySelector("#explorer_issue_a_id")
        this.issue_b_input = document.querySelector("#explorer_issue_b_id")
        this.level_input = document.querySelector("#explorer_level_id")
        this.level_range = document.querySelector("#level-range")
        // this.level_indicator = document.querySelector("#level-indicator-span")
        this.country_input = document.querySelector("#explorer_country_id")
        this.question_a = document.querySelector("#question-a")
        this.question_b = document.querySelector("#question-b")
        this.question_c = document.querySelector("#question-c")
        this.answer_a = document.querySelector("#explorer_answer_a")
        this.answer_b = document.querySelector("#explorer_answer_b")
        this.answer_c = document.querySelector("#explorer_answer_c")
        this.country_id = null
        if (this.cat_input) {
            this.categories = new Categories()
            this.events()
        }
    }

    events() {
        // const choices = new Choices(this.country_input, {
            //     silent: true
            // })
        this.answer_a.addEventListener("keyup", (e) => e.preventDefault())
        this.answer_b.addEventListener("keyup", (e) => e.preventDefault())
        this.answer_c.addEventListener("keyup", (e) => e.preventDefault())
        this.answer_a.addEventListener("keydown", (e) => {
            let value = this.answer_a.value
            if (e.keyCode == 13) {
                e.preventDefault()
                if(value) this.answer_b.focus()
            }
        })
        this.answer_b.addEventListener("keydown", (e) => {
            let value = this.answer_b.value
            if (e.keyCode == 13) {
                e.preventDefault()
                if(value) this.answer_c.focus()
            }
        })
        this.answer_c.addEventListener("keydown", (e) => {
            let value = this.answer_c.value
            if (e.keyCode == 13) {
                e.preventDefault()
                if(value) document.querySelector("#submit-btn").click()
            }
        })
        document.querySelectorAll(".issue-btn").forEach((el) => this.issueEvent(el))
        this.country_input.addEventListener("change", () => {
            this.country_id = this.country_input.value
            if (this.country_id) return this.categories.show()
            else return this.categories.hide()
        })
        this.level_range.addEventListener("input", () => this.updateLevel())
        this.level_range.addEventListener("change", () => {
            this.updateLevel()
            this.scrollTo("#answers-section")
            this.answer_a.focus()
        })
    }

    updateLevel() {
        const level = LEVELS[Number(this.level_range.value)]
        this.level_input.value = level.id
        let list = document.querySelector("#levels-list")
        //
        let active = list.querySelector("li.active")
        if (active) active.classList.remove("active")
        //
        let item = list.querySelectorAll("li")[Number(this.level_range.value)]
        if (item) item.classList.add("active")
        // this.level_indicator.innerText = level.name
        this.updateQuestions(level)
        this.show("#answers-section")
    }

    show(section) {
        gsap.to(section, {
            duration: .35,
            opacity: 1,
            display: "block"
        })
    }

    updateQuestions(level) {
        this.question_a.innerHTML = level.a
        this.question_b.innerHTML = level.b
        this.question_c.innerHTML = level.c
    }

    issueEvent(_btn) {
        _btn.addEventListener("click", (e) => {
            e.preventDefault()
            let id = _btn.dataset.id
            let name = _btn.dataset.name
            // Si ha sido previamente selccionado, quitamos clase y id asignado a input
            if (_btn.classList.contains("active")) {
                if (this.issue_a == id) {
                    this.issue_a_input.value = ""
                } else if (this.issue_b == id) {
                    this.issue_b_input.value = ""
                }
                _btn.classList.remove("active")
            } else {
                // Si no está seleccionada ningún issue, activamos
                if (!this.issue_a || !this.issue_b) {
                    if (!this.issue_a) {
                        this.issue_a_input.value = id
                        // NEW:
                        this.issue_b_input.value = id
                    } else if (!this.issue_b) {
                        this.issue_b_input.value = id
                    }
                    _btn.classList.add("active")
                }
            }
            // si seleccionaron 2 issues mostramos la siguiente pantalla
            if (this.issue_a && this.issue_b) {
                this.show("#level-security")
                gsap.delayedCall(.1, () => {
                    this.scrollTo("#level-security")
                })
            }
        })
    }

    scrollTo(_section) {
        let top = document.querySelector(_section).offsetTop
        let scroll = {
            y: window.scrollY
        }
        gsap.to(scroll, {
            y: top,
            onUpdate: () => {
                window.scroll(0, scroll.y)
            }
        })
    }

    get level() {
        return this.level_input.value
    }
    
    get issue_a() {
        return this.issue_a_input.value
    }
    
    get issue_b() {
        return this.issue_b_input.value
    }

    get category_id() {
        return this.cat_input.value
    }

    submit() {

    }
}